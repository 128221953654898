$(".expander__trigger").on("click", function() {
  const $this = $(this);
  const parent = $this.parent(".expander__heading").parent(".expander");
  const body = parent.children(".expander__body");

  if (parent.hasClass("expander--expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass("expander--expanded");
})
