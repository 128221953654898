const scrollDuration = 920;
const scrollEase= 'ease';

ScrollReveal().reveal('h1', {
  delay: scrollDuration,
  origin: 'left',
  distance: '5rem',
  duration: scrollDuration,
  easing: scrollEase,
  opacity: 0,
});

ScrollReveal().reveal('.standfirst, .super-h2', {
  delay: scrollDuration / 2,
  distance: '2rem',
  duration: scrollDuration,
  easing: scrollEase,
  opacity: 0
});

ScrollReveal().reveal('.company-tile__link, .team-tile__link', {
  delay: scrollDuration,
  easing: scrollEase,
  opacity: 0,
  interval: 100
});

ScrollReveal().reveal('.tag', {
  opacity: 0,
  easing: scrollEase,
  interval: 50
});

// Founder profile

ScrollReveal().reveal('.founder-profile__headshot-image', {
  delay: scrollDuration * 1.2,
  easing: scrollEase,
  origin: 'bottom',
  distance: '2rem',
  opacity: 0
});

ScrollReveal().reveal('.founder-profile__headshot-square', {
  delay: scrollDuration * 1.4,
  easing: scrollEase,
  opacity: 0
});

ScrollReveal().reveal('.founder-profile__logo', {
  delay: scrollDuration * 1.6,
  easing: scrollEase,
  opacity: 0
});

// Founder profile odd

ScrollReveal().reveal('.founder-profile:nth-child(odd) .founder-profile__headshot-bg', {
  delay: scrollDuration,
  easing: scrollEase,
  origin: 'left',
  distance: '5rem',
  opacity: 0
});

ScrollReveal().reveal('.founder-profile:nth-child(odd) .founder-profile__copy', {
  delay: scrollDuration,
  easing: scrollEase,
  origin: 'right',
  distance: '5rem',
  opacity: 0
});

// Founder profile even

ScrollReveal().reveal('.founder-profile:nth-child(even) .founder-profile__headshot-bg', {
  delay: scrollDuration,
  easing: scrollEase,
  origin: 'right',
  distance: '5rem',
  opacity: 0
});

ScrollReveal().reveal('.founder-profile:nth-child(even) .founder-profile__copy', {
  delay: scrollDuration,
  easing: scrollEase,
  origin: 'left',
  distance: '5rem',
  opacity: 0
});
