// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("siteHeader");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("site-header--stuck");
  } else {
    header.classList.remove("site-header--stuck");
  }
}

$(".mobile-menu-toggle").click(function() {
  $(".primary-nav").toggleClass("primary-nav--open");
  $(this).toggleClass("mobile-menu-toggle--open")
});
