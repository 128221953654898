console.log();

if (document.getElementsByClassName("company-tiles--homepage").length) {
  new Splide(".splide.company-tiles--homepage", {
    arrows: false,
    pagination: false,
    type: "loop",
    autoplay: "true",
    interval: "3000",
    padding: "20%",
    mediaQuery: "min",
    breakpoints: {
      640: {
        destroy: true,
      },
    },
  }).mount();
}

if (document.getElementsByClassName("people-tiles--homepage").length) {
  new Splide(".splide.people-tiles--homepage", {
    arrows: false,
    pagination: false,
    type: "loop",
    gap: "3rem",
    padding: "20%",
    mediaQuery: "min",
    breakpoints: {
      640: {
        type: "slide",
        perPage: 3,
        padding: { right: "10%" }
      },
      1024: {
        perPage: 4,
      }
    }
  }).mount();
}

var elms = document.getElementsByClassName( 'company-gallery' );

for ( var i = 0; i < elms.length; i++ ) {
  new Splide( elms[ i ], {
    arrows: false,
    pagination: false,
    type: 'loop',
    gap: '3rem',
    autoplay: "true",
    interval: "6000",
    mediaQuery: 'min',
    breakpoints: {
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3
      }
    }
  } ).mount();
}
